<template>
  <VueRecaptcha
    :sitekey="this.sitekey"
    :loadRecaptchaScript="true"
    @verify="validate"
  />
</template>
<script>
import VueRecaptcha from "vue-recaptcha";
import Validation from "@/services/recaptchaValidate";
import { ConfigService } from "../services/ConfigService.ts";

//import UnidadOrgService from "../services/UnidadOrgServices";

export default {
  components: { VueRecaptcha },
  data() {
    return {
      sitekey: ConfigService.RECAPTCHA_PUBLIC_KEY,

      //sitekey: "6LduEZ8cAAAAAOAuPmM0noN3cbpP5TsRW2VexWGQ",
    };
  },
  methods: {
    validate(response) {
      Validation.validate({ Response: response })
        .then((result) => {
          //console.log(result);
          this.$emit("validate", result.success);
        })
        .catch((error) => console.log('cachado de error:' + error));
    },
  },
};
</script>
