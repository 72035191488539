import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Axios from "axios";
//import VueAxios from "vue-axios";

//Vue.config.productionTip = false;
export const bus = new Vue();
////Vue.use(VueAxios, Axios);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
