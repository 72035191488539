import axios from "axios";
import { ConfigService } from "./ConfigService";

export default {
  async validate(params: any) {

    const result = await axios.post(`${ConfigService.baseURL}/Recaptcha`, params);
    return result.data;

    // return new Promise((resolve, reject) => { 
    //   axios
    //     .post(`${ConfigService.baseURL}/Recaptcha`, params)
    //     //axios.post(`https://localhost:62117/api/Recaptcha/validate`, params)
        
        
    //     .then((response:any) => {
    //       //console.log(ConfigService.baseURL);
    //       if (response.data.hasErrors) {
    //         //console.log("if")
    //         //console.log(response.data.message)
    //         reject(response.data.message);
    //       } else {
    //         //console.log("else")
    //         //console.log(response.data)
    //         resolve(response.data);
    //       }
    //     })
    //     .catch((e) => console.log(e));
    //   // .catch(error => {
    //   //   if (error.response.data.hasOwnProperty('hasErrors')) {
    //   //     reject(error.response.data.message)
    //   //   } else {
    //   //     reject(error.message)
    //   //   }
    //   // })
    // });
  },
};
