<template>
  <v-app>
    <v-app-bar color="#691c32" app>
      <v-img
        class="mx-2"
        src="../../public/img/logo-internet2022_129x40px.svg"
        max-height="45"
        max-width="155"
        contain
      ></v-img>
    </v-app-bar>
    <v-container class="fill-height pa-0 back" fluid :style="imagen">
      <!--       <v-app-bar app color="#691c32" dark>
        <div class="d-flex align-center">
          <v-img src="../../public/img/logo-internet2022_129x40px.svg"></v-img>
        </div>
      </v-app-bar> -->
      <v-row align="center" justify="center" class="fill-height pa-0">
        <v-col cols="12" sm="8" md="4">
          <v-form>
            <v-card :loading="cargando" elevation="4">
              <template slot="progress">
                <v-progress-linear
                  color="#691c32"
                  indeterminate
                ></v-progress-linear>
              </template>
              <v-toolbar>
                <v-spacer></v-spacer>
                <v-toolbar-title style="justify-center"
                  >e-Firma</v-toolbar-title
                >
                <v-spacer></v-spacer>
              </v-toolbar>

              <v-card-text>
                <v-text-field
                  id="txtUIDPaquete"
                  label="GUID de Paquete"
                  v-model="uidpaq"
                  :error-messages="uidErrors"
                  required
                  @input="$v.uidpaq.$touch()"
                  @blur="$v.uidpaq.$touch()"
                  append-icon="mdi-help-circle"
                  @click:append="(HelpType='Paq', HelpShow = !HelpShow)"
                  />
                  
                <div v-if="opt.cert" class="form-group">
                  <v-text-field
                    id="txtCertificdo"
                    label="Certificado"
                    v-model="certificado"
                    :error-messages="certificadoErrors"
                    required
                    @input="$v.certificado.$touch()"
                    @blur="$v.certificado.$touch()"
                  />
                </div>
                <div v-else class="form-group">
                  <v-text-field
                    id="txtUIDDocumento"
                    label="GUID de Documento"
                    v-model="uiddoc"
                    :error-messages="uidDocErrors"
                    required
                    @input="$v.uiddoc.$touch()"
                    @blur="$v.uiddoc.$touch()"
                    append-icon="mdi-help-circle"
                    @click:append="(HelpType='Doc', HelpShow = !HelpShow)"
                  />
                  
                  <v-file-input
                    v-model="firmaPDF"
                    @change="ObtienePDF()"
                    accept=".pdf"
                    required
                    :error-messages="firmaPDFErrors"
                    @input="$v.firmaPDF.$touch()"
                    @blur="$v.firmaPDF.$touch()"
                  ></v-file-input>
                </div>
                <v-row>
                  <v-col>
                    <div class="text-center justify-center align-center">
                      <Recaptcha @validate="validate" />
                    </div>
                  </v-col>
                  <v-col class="text-end">
                    <v-btn                                  
                      id="btnVerifFirma"
                      color="#A42145"
                      dark
                      @click="Siguientepag()"
                      >Verificar Firma</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-text>              
            </v-card>            
          </v-form>
        </v-col>
      </v-row>
      <v-dialog v-model="HelpShow" max-width="500px" :fullscreen="$vuetify.breakpoint.smAndDown">
        <info-helper-container :item="HelpType" @close="HelpShow = !HelpShow"/>
      </v-dialog>
    </v-container>
    <v-footer color="#2e2e2e">
      <Footer />
    </v-footer>
  </v-app>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { blobToSHA512 } from "file-to-sha512";
import Recaptcha from "@/components/Recaptcha";
import Footer from "@/components/Footer";
import InfoHelperContainer from "@/components/InfoHelperContainer.vue";

import { hex2b64 } from "jsrsasign";

export default {
  components: { Recaptcha, Footer, InfoHelperContainer },
  mixins: [validationMixin],
  name: "ValidaPage",
  data() {
    return {      
      HelpType: "",
      HelpShow: false,
      validateRecaptcha: false,
      imagen: {
        backgroundImage: "url(../img/FondoeFirma.jpg)",
        backgroundSize: "cover",
      },
      uidpaq: this.puidpaq,
      certificado: "",
      error: "",
      //alert: false,
      cargando: false,
      uiddoc: this.puiddoc,
      firmaPDF: null,
      base64: "",
      opt: {
        cert: false,
      },
    };
  },
  props: {
    puidpaq: {
      type: String,
      default: "",
    },
    puiddoc: {
      type: String,
      default: "",
    },
    /*     certificado: {
      type: String,
      default: "",
    }, */
  },
  validations: {
    uidpaq: { required, minLength: minLength(30) },
    certificado: { required, minLength: minLength(20) },
    uiddoc: { required, minLength: minLength(30) },
    firmaPDF: { required },
  },
  /*  mounted() {
    //this.verificapaquete(this.guid);
  }, */
  methods: {
    validate(success) {     
      this.validateRecaptcha = success;
      this.$forceUpdate();
      console.debug("Validate success");
    },
    Siguientepag() {

      if(!this.validateRecaptcha || !this.firmaPDF || !this.uidpaq || !this.uiddoc)
      {
        alert("Debes proporcionar todos los datos ya que son obligatorios para validar el documento.");
        return;
      }

      this.$store.commit("SET_OPT_DATA", this.opt.cert);
      if (this.opt.cert) {
       
        this.$store.commit("SET_GUID_DATA", this.uidpaq),
          this.$store.commit("SET_CERT_DATA", this.certificado);
      } else {
        if (this.base64.length !== 0) {
         
          this.$store.commit("SET_GUID_DATA", this.uidpaq),
            this.$store.commit("SET_CERT_DATA", this.certificado),
            this.$store.commit("SET_GUIDDOC_DATA", this.uiddoc),
            this.$store.commit("SET_FILE_DATA", this.base64);
        }
      }
      this.$router.push("/DatosPaquete");
    },
    async ObtienePDF() {
      if (this.firmaPDF !== null) {
        if (typeof this.firmaPDF !== undefined || this.firmaPDF !== undefined) {
          const hash = await blobToSHA512(this.firmaPDF);
          const l = hex2b64(hash);
          this.base64 = l;
        }
      }
    },
    HelperShow(item){
      console.log(item);
    }
  },
  computed: {
    uidErrors() {
      const errors = [];
      if (!this.$v.uidpaq.$dirty) return errors;
      !this.$v.uidpaq.minLength &&
        errors.push("El valor GUID debe contener un mínimo de 30 caracteres");
      !this.$v.uidpaq.required && errors.push("GUID es requerido.");
      return errors;
    },
    certificadoErrors() {
      const errors = [];
      if (!this.$v.certificado.$dirty) return errors;
      !this.$v.certificado.minLength &&
        errors.push("El valor debe contener un mínimo de 20 caracteres");
      !this.$v.certificado.required && errors.push("Certificado es requerido.");
      return errors;
    },
    uidDocErrors() {
      const errors = [];
      if (!this.$v.uiddoc.$dirty) return errors;
      !this.$v.uiddoc.minLength &&
        errors.push("El valor GUID debe contener un mínimo de 30 caracteres");
      !this.$v.uiddoc.required && errors.push("GUID es requerido.");
      return errors;
    },
    firmaPDFErrors() {
      const errors = [];
      if (!this.$v.firmaPDF.$dirty) return errors;
      !this.$v.firmaPDF.required && errors.push("Archivo es requerido.");
      return errors;
    },
  },
};
</script>
<style>
body {
  margin: 0;
}
.header {
  background: #691c32;
}
.header-top {
  display: flex;
  justify-content: space-between;
}
.header-logo {
  margin: 0;
}
</style>
