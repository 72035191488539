import { ConfigService } from "./ConfigService";
import axios from "axios";

const apiClient = axios.create({
  baseURL: ConfigService.baseURL,
  // withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-type": "application/json",
  },
});

export default {
  postVerificarPaquete(guid: string) {
    // console.log("postVerificarPaquete");
    // console.log(guid);
    return apiClient.post(`/DocumentoFirma/VerificarPaquete?guid=${guid}`);
  },
  postVerificardocumento(data: any) {
    // console.log("postVerificarDocumento");
    // console.log(data);
    return apiClient.post(`/DocumentoFirma/VerificarDocumento`, data);
  },
  postVerificarArchivoCargado(data: any) {
    return apiClient.post("/DocumentoFirma/VerificarArchivoCargado", data);
    //debugger;
  },
};
