import { ConfigService } from "./ConfigService";
import axios from "axios";

const apiClient = axios.create({
    baseURL: ConfigService.baseURL,
    // withCredentials: true,
    headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        'Access-Control-Allow-Origin': '*'
    },
});
export default {
    // postUploadFileSystemAndDB(data: any) {
    //     //console.log("postUploadFileSystemAndDB");
    //     //console.log(data);
    //     return apiClient.post("/Repositorio/UploadFileSystemAndDB", data);
    // },
    // deleteFileSystemAndDB(Id: number) {
    //     return apiClient.delete(`/Repositorio/DeleteFileSystemAndDB?Id=${Id}`);
    // },
    DisplayDocument(Id: number) {
        return apiClient.get(`Documentos/DisplayDoc?Id=${Id}`);
    },
    getRepresentacion: function (paqueteid: number, documentoid: number) {
        return apiClient.get("/Representacion/GetRepresentacionPd2fAsync/" + paqueteid + "/" + documentoid);
    },


}