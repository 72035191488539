<template>
  <v-app>
    <v-card outlined>
      <v-app-bar app color="#691c32" dark>
        <div class="d-flex align-center">
          <v-img src="../../public/img/logo-internet2022_129x40px.svg"></v-img>
        </div>

        <v-spacer></v-spacer>

        <v-btn @click="cerrarSesion" color="white" text>
          <span class="mr-2">Salir</span>
          <v-icon>mdi-home-import-outline</v-icon>
        </v-btn>
      </v-app-bar>
      <p></p>
      <v-alert
        outlined
        prominent
        border="left"
        :type="typeAlert"
        :value="alert"
        v-if="typeAlert"
      >
        {{ msgnohaydoctos }}
      </v-alert>

      <v-row cols="12" style="height: 94%">
        <v-col cols="12" md="6" lg="5">
          <v-card raised height="100%" :loading="cargando" v-show="nohaydoctos">
            <template slot="progress">
              <v-progress-linear
                color="#691c32"
                indeterminate
              ></v-progress-linear>
            </template>
            <v-flex class="d-flex grow flex-wrap pa-3">
              <v-sheet
                rounded
                class="pa-3"
                color="#691c32"
                height="50"
                width="auto"
              >
                <v-icon dark center>mdi-package-variant</v-icon>
              </v-sheet>
              <v-card-title style="padding-top: 10px; padding-bottom: 10px">
                <h4>Información de firmado</h4>
              </v-card-title>
            </v-flex>

            <!-- CUADRO INFORMACION GENERAL PAQUETE -->
            <v-card-text v-show="nohaydoctos">
              <v-row cols="12">
                <v-col cols="4">
                  <h4>Título</h4>
                  {{ paquete.titulo }}
                </v-col>
                <v-col cols="4">
                  <h4>Fecha</h4>
                  {{ formatDate(paquete.fecha) }}
                </v-col>
              </v-row>
              <v-row cols="12">
                <v-col cols="4">
                  <h4>Estatus</h4>
                  {{ paquete.estatusDescripcion }}
                </v-col>
                <v-col cols="7">
                  <h4>GUID Paquete</h4>
                  {{ paquete.guidpaquete }}
                </v-col>
              </v-row>
              <v-row cols="12">
                <v-col cols="4">
                  <h4>Descripción</h4>
                  {{ paquete.descripcion }}
                </v-col>
                <v-col cols="4">
                  <h4>ID Paquete</h4>
                  {{ paquete.id }}
                </v-col>
              </v-row>
            </v-card-text>
            <!-- FIN CUADRO INFORMACION GENERAL PAQUETE -->

            <!-- CUADRO DOCUMENTOS -->
            <v-flex class="d-flex grow flex-wrap pa-3">
              <v-sheet
                rounded
                class="pa-3"
                color="#691c32"
                height="50"
                width="auto"
              >
                <v-icon dark center>mdi-file-multiple</v-icon>
              </v-sheet>
              <v-card-title style="padding-top: 10px; padding-bottom: 10px">
                <h4>Documentos incluidos en el paquete</h4>
              </v-card-title>
            </v-flex>
            <v-card-text class="pt-0">
              <v-data-iterator
                :items="documentos"
                no-data-text="No hay documentos en este paquete"
                :hide-default-footer="muestraFooter"
                pagination.sync="pagination: {
                rowsPerPage: 2
            }"
                :footer-props="{
                  showFirstLastPage: true,
                  'items-per-page-text': 'Documentos por página',
                }"
              >
                <template v-slot:default="props">
                  <v-row
                    v-for="item in props.items"
                    :key="item.documentoId"
                    cols="12"
                    sm="12"
                  >
                    <v-col cols="6" sm="6">
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon dark center color="#691c32"
                            >mdi-file-pdf-box</v-icon
                          >
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            >{{ item.titulo }}
                          </v-list-item-title>
                          <span>{{ item.nombreOrig }}</span>
                          <v-list-item-subtitle
                            >{{
                              formatTamanio(item.tamanio)
                            }}
                            MB</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item></v-col
                    >

                    <v-col cols="1" sm="1">
                      <v-list-item>
                        <v-list-item-icon
                          v-if="cert.length !== 0"
                          style="cursor: pointer"
                          @click="DatosDocto(item.guiddocumento)"
                        >
                          <v-icon color="#691c32">mdi-eye</v-icon>
                        </v-list-item-icon>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </template>
              </v-data-iterator>
            </v-card-text>
            <!-- FIN CUADRO DOCUMENTOS -->
          </v-card>
        </v-col>

        <!-- CUADRO FIRMANTES -->
        <v-col cols="12" md="6" lg="5">
          <v-card raised height="100%" :loading="cargando" v-show="nohaydoctos">
            <template slot="progress">
              <v-progress-linear
                color="#691c32"
                indeterminate
              ></v-progress-linear>
            </template>
            <v-flex class="d-flex grow flex-wrap pa-3">
              <v-sheet
                rounded
                class="pa-3"
                color="#691c32"
                height="50"
                width="auto"
              >
                <v-icon dark center>mdi-account-star</v-icon>
              </v-sheet>
              <v-card-title style="padding-top: 10px; padding-bottom: 10px">
                <h4>Firmantes</h4>
              </v-card-title>
            </v-flex>

            <v-card-text class="pl-0">
              <v-timeline align-top dense class="pt-0">
                <v-timeline-item
                  v-for="(firmante, i) in firmantes"
                  :key="i"
                  :color="firmante.color"
                  :icon="firmante.icono"
                  fill-dot
                >
                  <v-card>
                    <v-card-text class="pa-0">
                      <v-list-item style="cursor: pointer">
                        <v-list-item-avatar color="grey" class="pr-0"
                          ><img
                            alt="Avatar"
                            src="../../public/img/AvatarAnonymos.png"
                        /></v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            firmante.nombre
                          }}</v-list-item-title>
                          <v-list-item-subtitle
                            ><v-chip :color="firmante.color" outlined label>{{
                              firmante.estatusFirmaDescripcion
                            }}</v-chip></v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
              </v-timeline>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- FIN CUADRO FIRMANTES -->
      </v-row>
    </v-card>
    <v-footer color="#2e2e2e">
      <Footer />
    </v-footer>
  </v-app>
</template>

<script>
import VerificacionService from "@/services/VerificacionService";
import RepositorioService from "../services/RepositorioService";
import Footer from "@/components/Footer";

export default {
  components: { Footer },
  data() {
    return {
      paquete: [],
      documentos: [],
      firmantes: [],
      cargando: true,
      guid: "",
      cert: "",
      muestraFooter: true,
      nohaydoctos: false,
      msgnohaydoctos: null,
      guiddoc: "",
      file: "",
      mensaje: "",
      alert: false,
      typeAlert: null,
      opt: null,
      errorGetValidacion: null,
    };
  },
  created() {
    this.guid = this.$store.state.guid;
    this.cert = this.$store.state.cert;
    this.guiddoc = this.$store.state.guiddoc;
    this.file = this.$store.state.file;
    this.opt = this.$store.state.opt;
    
  },
  mounted() {
    if (this.opt) {
      this.verificapaquete(this.guid);
    } else {
      this.verificarArchivoCargado();
    }
  },
  methods: {
    async verificapaquete(guid) {
 try {
   const response=await VerificacionService.postVerificarPaquete(guid)      
          
            if (response.data.esValido) {
              this.paquete = response.data;
              this.documentos = this.paquete.documentos;
              this.firmantes = this.paquete.firmantes;
              this.msgnohaydoctos = this.paquete.mensaje;
              this.typeAlert = "success";
              this.alert = true;
              if (this.documentos.length > 10) this.muestraFooter = false;
              else this.muestraFooter = true;
              this.nohaydoctos = true;
            } else {
              this.paquete = response.data;
              this.msgnohaydoctos = this.paquete.mensaje;
              this.typeAlert = "warning";
              this.alert = true;
              this.nohaydoctos = false;
              this.muestraFooter = true;
            }
         } catch (err) {
        console.error(err);
        this.errorGetValidacion = (err.response ?  err.response.data : err.message) || err;
        alert(this.errorGetValidacion);
      }
      finally{this.cargando = false;}
    },
    async verificarArchivoCargado() {
      let formData = "";
      formData = new FormData();
      formData.append("guidpaquete", this.guid);
      formData.append("guiddocumento", this.guiddoc);
      formData.append("certificado", this.cert);
      formData.append("file", this.file);

      const response=await VerificacionService.postVerificarArchivoCargado(formData)
        try {
        
            if (response.data.esValido) {
              this.paquete = response.data;

              this.msgnohaydoctos = this.paquete.mensaje;
              this.documentos = this.paquete.documentos;
              this.firmantes = this.paquete.firmantes;
              this.typeAlert = "success";
              this.alert = true;
              if (this.documentos.length > 10) this.muestraFooter = false;
              else this.muestraFooter = true;
              this.nohaydoctos = true;
            } else {
              this.paquete = response.data;

              this.msgnohaydoctos = this.paquete.mensaje;
              this.nohaydoctos = false;
              this.muestraFooter = true;
              this.typeAlert = "warning";
              this.alert = true;
            }
            this.cargando = false;
          
        } catch (err) {
        console.error(err);
        this.errorGetValidacion = (err.response ?  err.response.data : err.message) || err;
        alert(this.errorGetValidacion);
      }
    },
    formatDate: function (fecha) {
      if (typeof fecha !== "undefined") {
        const nuevaFecha = new Date(fecha);
        const ms = [
          "Ene",
          "Feb",
          "Mar",
          "Abr",
          "May",
          "Jun",
          "Jul",
          "Ago",
          "Sep",
          "Oct",
          "Nov",
          "Dic",
        ];
        return (
          nuevaFecha.getDate() +
          "-" +
          ms[nuevaFecha.getMonth()] +
          "-" +
          nuevaFecha.getFullYear()
        );
      } else {
        return "";
      }
    },
    formatTamanio: function (tamanio) {
      return Math.round(tamanio / 1024, 2);
    },
    async AbreVinculo(vinculo) {
        try {
        const response = await RepositorioService.DisplayDocument(vinculo.documentoId)
        
          this.openurl = response.request.responseURL;
          window.open(this.openurl);
       } catch (err) {
        console.error(err);
        this.errorGetValidacion = (err.response ?  err.response.data : err.message) || err;
        alert(this.errorGetValidacion);
      }

    },
    DatosDocto(guiddocto) {
      this.$router.push(
        "/DocumentosPaquete/" + this.guid + "/" + this.cert + "/" + guiddocto
      );
    },
    cerrarSesion: function () {
      this.$store.commit("SET_GUID_DATA", ""),
        this.$store.commit("SET_CERT_DATA", ""),
        this.$store.commit("SET_GUIDDOC_DATA", ""),
        this.$store.commit("SET_FILE_DATA", ""),
        this.$router.push({ name: "Verificacion" });
    },
  },
};
</script>
