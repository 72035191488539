<template>
  <v-container>
    <v-footer color="#2e2e2e" padless style="width: 100%">
      <div class="row">
        <div
          class="col-sm-6"
          style="
            font-family: 'Roboto', Verdana, sans-serif;
            font-size: 13px;
            color: #ffffff;
            text-align: left;
          "
        >
          © Petróleos Mexicanos.<br />Avenida Marina Nacional 329,<br />Colonia
          Verónica Anzures, C.P. 11311 Miguel Hidalgo, Ciudad de México, CDMX
          (+52) 55 1944 2500.
          <!--  <v-card flat width="100%" color="#2e2e2e">
            <v-card-text
              style="
                font-family: 'Roboto', Verdana, sans-serif;
                font-size: 13px;
                color: #ffffff;
                text-align: left;
              "
            >
              © Petróleos Mexicanos.<br />Avenida Marina Nacional 329,<br />Colonia
              Verónica Anzures, C.P. 11311 Miguel Hidalgo, Ciudad de México,
              CDMX (+52) 55 1944 2500.
            </v-card-text>
          </v-card> -->
        </div>
        <div class="col-sm-6">
          <ul
            style="
              list-style-type: none;
              margin: 0;
              padding: 0;
              line-height: 280%;
            "
          >
            <li>
              <img
                style="vertical-align: middle"
                src="../../public/img/ligas.svg"
                alt="ligas"
                class="icono_footer"
              />
              <a
                style="color: #ffffff"
                href="https://www.pemex.com/etica_y_transparencia/datos-personales/Paginas/default.aspx"
                target="_blank"
                >Datos personales</a
              >
            </li>
            <li>
              <img
                style="vertical-align: middle"
                src="../../public/img/ligas.svg"
                alt="ligas"
                class="icono_footer"
              />
              <a style="color: #ffffff" href="mailto:veronica.magana@pemex.com?Subject=PEMEX%20Validar%20Firma%20Electrónica">Contacto</a>
            </li>
          </ul>
          <!-- <v-card flat width="100%" color="#2e2e2e">
            <v-card-text>
              <ul
                style="
                  list-style-type: none;
                  margin: 0;
                  padding: 0;
                  line-height: 280%;
                "
              >
                <li>
                  <img
                    style="vertical-align: middle"
                    src="../../public/img/ligas.svg"
                    alt="ligas"
                    class="icono_footer"
                  />
                  <a
                    style="color: #ffffff"
                    href="https://www.pemex.com/etica_y_transparencia/datos-personales/Paginas/default.aspx"
                    target="_blank"
                    >Datos personales</a
                  >
                </li>
                <li>
                  <img
                    style="vertical-align: middle"
                    src="../../public/img/ligas.svg"
                    alt="ligas"
                    class="icono_footer"
                  />
                  <a style="color: #ffffff" href="#" target="_blank"
                    >Contacto</a
                  >
                </li>
              </ul>
            </v-card-text>
          </v-card> -->
        </div>
      </div>
    </v-footer>
  </v-container>
</template>

<script>
export default {};
</script>
<style>
.icono_footer {
  width: 30px;
  margin-right: 10px;
}
a:link,
a:visited {
  text-align: center;
  text-decoration: none;
  display: inline-block;
}
ul li {
  font-family: "Roboto", Verdana, sans-serif;
  font-size: 13px;
}
</style>