import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    guid:null,
    cert:null,
    guiddoc:null,
    file:null,
    opt:null,
  },
  mutations: {
    SET_GUID_DATA(state, guidData) {
      state.guid = guidData;     
    },
    SET_CERT_DATA(state, certData) {
      state.cert = certData;     
    },
    SET_GUIDDOC_DATA(state, guiddocData) {
      state.guiddoc = guiddocData;     
    },   
    SET_FILE_DATA(state, fileData) {
      state.file = fileData;     
    },   
    SET_OPT_DATA(state, opt) {
      state.opt = opt;     
    },  
    CLEAR_USER_DATA(state) {
      state.guid = null;
      state.cert = null;
      state.guiddoc = null;
      state.file = null;
      state.opt = null;
      location.reload();
    },
  },
  actions: {},
  modules: {},
});
