import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Verificacion from "@/views/Verificacion.vue";
import DocumentosPaquete from "@/views/DocumentosPaquete.vue";
import DatosPaquete from "@/views/DatosPaquete.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Verificacion",
    component: Verificacion,
  },
  {
    path: "/:puidpaq/:puiddoc",
    component: Verificacion,
    props: true
  },  
  {
    path: "/DocumentosPaquete/:guid/:cert/:guiddoc",
    name: "DocumentosPaquete",
    component: DocumentosPaquete,
  },
  {
    // path: "/DatosPaquete/:guid/:cert",
    path: "/DatosPaquete",
    name: "DatosPaquete",
    component: DatosPaquete,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});


export default router;
