




































import { Component, Prop, Vue } from "vue-property-decorator";
@Component({})
export default class InfoHelperContainer extends Vue {
  @Prop({ type:String })
  protected item!:string    
}  
