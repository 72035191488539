import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          dark: {
            aceptar: "#691C32",
            cancelar: "#bc955a",
            verde: "#4CAF50",
            amarillo: "#FBC02D",
            rojo: "#E53935",
            gris: "#90A4AE"
          },
          light: {
            aceptar: "#691C32",
            cancelar: "#bc955a",
            verde: "#4CAF50",
            amarillo: "#FBC02D",
            rojo: "#E53935",
            gris: "#90A4AE"
          },
        },
      },
});
