export const ConfigService = {
  //baseURL: "https://localhost:62117/api", //Desarrollo
  //baseURL: "http://esingapi.pemex.pmx.com/api" //Calidad
 // baseURL: "http://vwazuwebd002.un.pemex.com:2345/api", //Desarrollo
  //baseURL: "https://firmades-back.pemex.com/api", //Desarrollo
  //baseURL: "https://firmacal-back.pemex.com/api", //Desarrollo
  baseURL: "/external",
  NODE_ENV: "development",
  RECAPTCHA_PUBLIC_KEY: "6LduEZ8cAAAAAOAuPmM0noN3cbpP5TsRW2VexWGQ",
};
