<template>
  <v-app>
    <v-card>
      <v-app-bar app color="#691c32" dark>
        <div class="d-flex align-center">
          <v-img src="../../public/img/logo-internet2022_129x40px.svg"></v-img>
        </div>

        <v-spacer></v-spacer>

        <v-btn @click="goBack()" color="white" text>
          <span class="mr-2">Regresar</span>
          <v-icon>mdi-arrow-left-circle</v-icon>
        </v-btn>
      </v-app-bar>
      <!--     <v-container fluid>
      <v-system-bar color="aceptar" dark style="height: 48px">
                
        <v-col cols="6" class="xs-6 sm-6">
          <v-toolbar-title style="width: 230px" class="ml-0 pl-0">
            <v-img src="../../public/img/logoPemex.png"></v-img>
           
          </v-toolbar-title>

        </v-col>
        
        <v-toolbar-title center>
           <h4>Datos Documento Firmado</h4>
        </v-toolbar-title>
        <v-spacer></v-spacer>
       <v-tooltip top v-show="btnregresar">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  
                   v-on:click="goBack()"
                  
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="36px">mdi-arrow-left-circle </v-icon ></v-btn
                >
              </template>
              <span>REGRESAR</span>
            </v-tooltip>
        
      </v-system-bar>
   </v-container>
 -->
      <v-container fluid>
        <v-card class="mx-auto" :loading="cargando" outlined>
          <template slot="progress">
            <v-progress-linear
              color="#691c32"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-flex class="d-flex grow flex-wrap pa-3">
            <v-sheet
              rounded
              class="pa-3"
              color="#691c32"
              height="50"
              width="auto"
            >
              <v-icon dark center>mdi-file-document</v-icon>
            </v-sheet>
            <v-card-title style="padding-top: 10px; padding-bottom: 10px">
              <h4>{{ msgnohaydoctos }}</h4>
            </v-card-title>
          </v-flex>

          <!-- CUADRO INFORMACION DE DOCUMENTO -->
          <v-card-text v-show="nohaydoctos">
            <v-row cols="12">
              <v-col cols="8">
                <h4>Documento</h4>
                {{ datosdoc.titulo }}
              </v-col>
            </v-row>
            <v-row cols="12">
              <v-col cols="8">
                <h4>Documento {{ edodocto }}</h4>
                <h4>{{ datosdoc.observaciones }}</h4>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-spacer></v-spacer>
        <!-- CUADRO FIRMAS -->

        <v-card
          class="mx-auto"
          :loading="cargando"
          outlined
          v-show="nohaydoctos"
        >
          <template slot="progress">
            <v-progress-linear
              color="#691c32"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-flex class="d-flex grow flex-wrap pa-3">
            <v-sheet
              rounded
              class="pa-3"
              color="#691c32"
              height="50"
              width="auto"
            >
              <v-icon dark center>mdi-checkbox-multiple-marked-outline</v-icon>
            </v-sheet>
            <v-card-title style="padding-top: 10px; padding-bottom: 10px">
              <h4>Firmas</h4>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-card-text>
              <p v-html="datosdoc.representacionfirma"></p>
            </v-card-text>
          </v-flex>
        </v-card>
      </v-container>
      <!-- CUADRO FIRMAS -->
    </v-card>
    <v-footer color="#2e2e2e">
      <Footer />
    </v-footer>
  </v-app>
</template>

<script>
import VerificacionService from "@/services/VerificacionService";
import Footer from "@/components/Footer";
export default {
  components: { Footer },
  data() {
    return {
      cargando: true,
      guid: this.$route.params.guid,
      cert: this.$route.params.cert,
      guiddoc: this.$route.params.guiddoc,
      datosdoc: null,
      edodocto: null,
      nohaydoctos: false,
      msgnohaydoctos: null,
      btnregresar: true,
      firstEnter: false,
      errorGetValidacion: null,
      // titdocto:null,
      // observaciones:null,
      // firma:null,
    };
  },
  created() {
    if (this.$store.state.guid == null) {
      this.btnregresar = false;
    }
  },
  mounted() {
    this.verificadocumento(this.cert, this.guiddoc);
  },
  methods: {
    async verificadocumento(cert, guiddoc) {
      let data = "";

      data = {
        Guid: guiddoc,
        Certificado: cert,
      };
      try{
        
        const response = await VerificacionService.postVerificardocumento(data)
     console.log(response);

          if (typeof response.data.iddocto != "undefined") {
            this.datosdoc = response.data;

            this.nohaydoctos = true;
            this.msgnohaydoctos = "Documento: " + this.datosdoc.documento;

            this.cargando = false;

            if (this.datosdoc.estado == true) {
              this.edodocto = "Valido";
            } else {
              this.edodocto = " NO Valido";
              this.nohaydoctos = false;
              this.msgnohaydoctos = "DOCUMENTO NO VALIDO";
            }
          } else {
            this.msgnohaydoctos = "DOCUMENTO NO VALIDO";

            this.nohaydoctos = false;
            this.muestraFooter = true;
          }
       
        }
      catch (err) {
        console.error(err);
        this.errorGetValidacion = (err.response ?  err.response.data : err.message) || err;
        alert(this.errorGetValidacion);
      }
    },
    goBack() {
      this.$store.commit("SET_GUID_DATA", this.guid);
      this.$store.commit("SET_CERT_DATA", this.cert);
      if (this.firstEnter) {
        this.$router.push({ path: "/" });
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>
